<template>
  <b-form-group
    class="mt-1"
    label-for="boolInput"
  >
    <label
      v-if="type != 'statement'"
      v-html="name"
    />

    <!-- Boolean -->
    <div v-if="type === 'bool'" class="d-flex justify-content-start align-items-center">
      <b-form-radio-group
        v-model="answer"
        :options="options"
        :disabled="true"
        @change="answerChanged"
      />

      <!-- Open Evidence -->
      <b-button
        v-if="behaviour.evidenceKey && behaviour.evidenceKey.length"
        class="ml-1"
        size="sm"
        variant="warning"
        @click="openDocument(behaviour.evidenceKey)"
      >
        {{ `${$t('message.seeEvidence')}${behaviour.evidenceKey.length > 1 ? 's' : ''}` }}
      </b-button>

      <!-- See case generated for this behaviour -->
      <b-button
        v-if="behaviour.caseId"
        class="ml-1"
        size="sm"
        variant="info"
        @click="openCase(behaviour.caseId)"
      >
        {{ $t('message.see_case') }}
      </b-button>
    </div>

    <!-- Rating -->
    <b-form-rating
      v-if="type === 'rating'"
      v-model="answer"
      size="lg"
      inline="inline"
      no-border="no-border"
      variant="warning"
      :disabled="true"
      class="ml-1 mr-1"
      @change="answerChanged"
    />
    <strong v-if="type === 'rating'" :style="{ fontSize: '1.15rem' }">
      {{ answer ? answer : 'N/A' }}
    </strong>

    <!-- Text -->
    <b-form-textarea
      v-if="type === 'text'"
      v-model="answer"
      rows="3"
      :disabled="true"
      @change="answerChanged"
    />

    <!-- Statement -->
    <div v-if="type === 'statement'">
      <b-card
        v-html="showSeeMoreButton && showShortStatement ? shortStatement : name"
        class="mb-0"
      />
      <b-button
        v-if="showSeeMoreButton"
        size="sm"
        :variant="showShortStatement ? 'info' : 'warning'"
        @click="showShortStatement = !showShortStatement"
      >
        {{ showShortStatement ? $t('message.see_more') : $t('message.see_less') }}
      </b-button>
    </div>

    <!-- Default text field for comments -->
    <b-form-input
      v-if="comment"
      v-model="comment"
      class="mt-1"
      :disabled="true"
    />

  </b-form-group>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BFormGroup, BFormRadioGroup, BFormRating, BFormTextarea } from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import { useRouter } from '@core/utils/utils'
import useCommon from '@/views/organization/useCommon'

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormRating,
    BFormTextarea,
  },

  props: {
    behaviour: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      name: this.behaviour.name,
      type: this.behaviour.type,
      answer: this.behaviour.answer,
      comment: this.behaviour.comment,
    }
  },

  computed: {
    shortStatement() {
      if (this.type === 'statement') {
        return this.name.slice(0, 100) + "..."
      }
    },
    showSeeMoreButton() {
      if (this.type === 'statement' && this.name?.length > 100) return true
      return false
    }
  },

  methods: {
    answerChanged(answer) {
      this.$emit('answered', answer)
    },
  },

  setup() {
    const { openDocument } = useCommon()
    const { router } = useRouter()
    const showShortStatement = ref(true)

    const options = [
      { text: i18n.t('message.yes'), value: 'true' },
      { text: 'No', value: 'false' },
      { text: 'N/A', value: 'unknown' },
    ]

    const openCase = (id) => {
      const routeData = router.resolve({ name: 'organization-case-view', params: { id } });
      window.open(routeData.href, '_blank');
    }

    return {
      options,
      showShortStatement,
      openCase,
      openDocument,
    }
  },
}
</script>
